<template>
    <div class="mct-modulos container">
        <div class="row">
            <div class="col" v-for="(smodulo,index) in data" :key="index" @click="abrirSubModulo($event,smodulo)" :style="'background:'+smodulo.smod_cor">
            
                <h1>{{ smodulo.smod_descricao }}</h1>
                <img :src="'src/assets/'+smodulo.smod_imagem">
                <p>{{ smodulo.smod_desclonga }}</p>
            
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'
import LayoutDefault from '@/components/layouts/LayoutDefault.vue';

export default {
    data (){
        return {
            data: {}
        }
    },
    methods: {
        retornaLista () {
            var vm = this
            axios.get(`/modulos_sub/`+this.infoModulo.mod_codigo+`/lista`)
            .then(function(response) {
                Vue.set(vm.$data, 'data', response.data.data)  
                if ( vm.$data.data.length === 0 ||  
                     vm.$data.data == null ||
                     vm.$data.data == undefined ){
                    console.log('Sem Sub Modulo')    
                    this.$router.push({path: '/'})                 
                   }else  if (vm.$data.data.length === 1){
                    vm.abrirSubModulo(null,vm.$data.data[0])   
                    }              
            })
            .catch(function(response) {
                console.log(response.message)
            })
        },
        abrirSubModulo (ev, smodulo) {
            if (smodulo.smod_codigo != 0 && smodulo.smod_codigo != null && smodulo.smod_codigo != 'undefined')
            { 
                this.$store.dispatch('Login/USU_SUBMODULO', smodulo);
                this.$store.dispatch('Login/MENU');
                this.$store.dispatch('Login/LAYOUT',LayoutDefault);
                if (smodulo.smod_pagina != '' && smodulo.smod_pagina != null){
                    this.$router.push({path: smodulo.smod_pagina})  
                }else{
                    this.$router.push({path: '/'})    
                }
            }
        },
        logout () {

        }
    },
    computed: {
        ...mapState('Login',['infoModulo'])
    },    
    created() {
        this.retornaLista()
    }
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=PT+Sans');

     .mct-modulos * {
        font-family: 'PT Sans', Helvetica, Arial, Verdana, sans-serif;
    }
    
    .mct-modulos{
        text-align: center;
        cursor: pointer;
        margin-top: 20px;
    }

    .mct-modulos h1{
        color: #ffffff;
        font-size: 1.2em;
        font-weight: bold;
    }

    .mct-modulos p{
        width: 100%;
        color: #ffffff;
        font-size: 0.7em;
        font-weight: normal;
        position: absolute;
        bottom: 0;
    }

    .mct-modulos img{
        max-height: 100px;
    }

    .mct-modulos .col{
        min-width: 200px;
        max-width: 200px;
        height: 200px;
        background-color: red;
        /*margin: 5px;*/
        padding: 2px;
        overflow: hidden;

        position: relative;
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1); 
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1); 
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1);
        transition: all 200ms ease-in;
        transform: scale(1);          
    }

    .mct-modulos .col:hover {
        box-shadow: 1px 1px 1px #FFFFFF;
        z-index: 9999;
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1.2);
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1.2);   
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1.2);
        transition: all 200ms ease-in;
        transform: scale(1.2);  
    }

    .row {
        margin: 0 auto;
        text-align: center;
    }
    
</style>
